
.table-mensagem thead tr th {
  padding: .20rem;
}

.table-mensagem {
  font-size: 11px;
}

.form-mensagem {
  padding: 6px;  
  font-size: 11px;
  padding-bottom: 4px;
}


.form-mensagem .form-row .form-group {
  margin-bottom: 4px!important;
}

.form-mensagem .form-row .form-group label{
  margin-bottom: 2px!important;
}

.norezise {
  resize: none;
}